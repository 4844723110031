function updateDropdownPosition() {
  var $amegamenu = $('#amegamenu');
  if ($amegamenu.length) {
    var updatePosition = () => {
      if ($amegamenu.hasClass('amegamenu_rtl')) {
        updateDropdownPositionRTL($amegamenu);
      } else {
        updateDropdownPositionLTR($amegamenu);
      }
    };

    updatePosition();
    $(window).on('resize', updatePosition);
  }
}

function updateDropdownPositionLTR(mm) {
  const styles = {
    full: 'full',
    initial: 'initial',
    right: 'right',
    left: 'left',
    middle: 'middle',
  };
  const marginLeft = mm.offset().left;
  const marginRight = mm.offset().left + mm.outerWidth();
  // define if dropdown is full
  const styleSelected = styles.initial;
  // define if dropdown is align left or right
  const styleAlign = styles.left;

  if (styleSelected === styles.full) {
    document.querySelectorAll('.adropdown').forEach((dropdown) => {
      const dropdownCurrent = dropdown;
      const menuItem = dropdownCurrent.closest('.amenu-item');
      const menuItemLeft = 0 - menuItem.offsetLeft;
      dropdownCurrent.style.marginLeft = `${menuItemLeft}px`;
      dropdownCurrent.style.width = `${window.outerWidth}px`;
    });
  } else if (styleSelected === styles.initial) {
    document.querySelectorAll('.adropdown').forEach((dropdown) => {
      const dropdownCurrent = dropdown;
      const menuItem = dropdownCurrent.closest('.amenu-item');
      const menuItemWidth = menuItem.offsetWidth;
      const menuItemLeft = menuItem.offsetLeft;
      const dropdownWidth = dropdownCurrent.offsetWidth;
      const dropdownLeft = dropdownCurrent.offsetLeft;
      const dropdownMiddle = 0 - (dropdownWidth) / 2;
      const dropdownRight = dropdownLeft + dropdownWidth;
      const margin = Number(dropdownCurrent.style.marginLeft.slice(0, -2));

      if (styleAlign === styles.right) {
        if (dropdownRight - margin + dropdownMiddle + menuItemLeft > marginRight) {
          dropdownCurrent.style.marginLeft = `${marginRight - menuItemLeft - dropdownWidth}px`;
        } else {
          dropdownCurrent.style.marginLeft = `${dropdownMiddle}px`;
        }
      } else if (styleAlign === styles.left) {
        if (dropdownLeft - margin + dropdownMiddle + menuItemLeft < -marginLeft && marginLeft !== 0) {
          dropdownCurrent.style.marginLeft = `${-menuItemLeft}px`;
        } else if (marginLeft === 0 && -menuItemLeft > dropdownMiddle) {
          dropdownCurrent.style.marginLeft = `${-menuItemLeft}px`;
        } else {
          dropdownCurrent.style.marginLeft = `${dropdownMiddle + (menuItemWidth / 2)}px`;
        }
      }
    });
  }
}

function updateDropdownPositionRTL(mm) {
  var m_left = mm.offset().left,
      m_right = mm.offset().left + mm.outerWidth();

  $('.adropdown', mm).each(function () {
    let t = $(this),
      p = t.parent(),
      i = 0 - (t.outerWidth() - p.outerWidth()) / 2,
      t_right = t.offset().left + t.outerWidth(),
      p_right = p.offset().left + p.outerWidth(),
      margin = parseInt(t.css('margin-right'));

    if (t.offset().left + margin - i < m_left) {
      t.css('margin-right', (0 - (t.outerWidth() - p_right + m_left)) + 'px');
    } else if (t_right + margin - i > m_right) {
      t.css('margin-right', (0 - (m_right - p_right)) + 'px');
    } else {
      t.css('margin-right', i + 'px');
    }
  });
}

function enableHoverMenuOnTablet() {
  $('html').on('touchstart', function (e) {
    $('#amegamenu .amenu-item').removeClass('hover');
  });
  $('#amegamenu').on('touchstart', function (e) {
    e.stopPropagation();
  });
  $('#amegamenu .amenu-item.has-submenu > .amenu-link').on('touchstart', function (e) {
    var li = $(this).parent('li');
    if (li.hasClass('hover')) {
      return true;
    } else {
      $('#amegamenu .amenu-item').removeClass('hover');
      li.addClass('hover');
      e.preventDefault();
      return false;
    }
  });
}

function ajaxLoadDrodownContent() {
  var $ajaxmenu = $('.js-ajax-mega-menu');
  if ($ajaxmenu.length) {
    $.ajax({
      type: 'POST',
      url: $ajaxmenu.data('ajax-dropdown-controller'),
      data: {
        ajax: true,
      },
      dataType: 'json',
      success: function (data) {
        updateDrodownContent(data);
        // if mobile
        if (document.querySelector('#mobile-amegamenu') !== null) {
          sldMenuResponsive(true);
        } // if desktop
        else {
          // init page
          sldBreakWidth();

        }
      },
      error: function (err) {
        console.log(err);
      }
    });

    var updateDrodownContent = function (dropdown) {
      $('.js-dropdown-content', $ajaxmenu).each(function () {
        const item = $(this).data('menu-id');
        $(this).replaceWith(dropdown[item]);
      });
    };
  }
}

function sldBreakWidth() {
  if (window.innerWidth < 1260 && !document.body.classList.contains('sld-menu-responsive')) {
    sldMoveMenuDesktop(true);
    document.body.classList.add('sld-menu-responsive');
  } else if (window.innerWidth >= 1260 && document.body.classList.contains('sld-menu-responsive')) {
    sldMoveMenuDesktop(false);
    document.body.classList.remove('sld-menu-responsive');
  }
}

function sldMoveMenuDesktop(activeMenuMobile) {
  const containerMain = document.querySelector('.header-display-navfullwidth');
  const containerLeft = document.querySelector('.sidebar-menu');
  const menu = document.querySelector('#amegamenu');
  if (containerMain !== null && containerLeft !== null && menu !== null) {
    // if column left not contains menu
    if (containerLeft.querySelector('#amegamenu') === null && activeMenuMobile) {
      containerLeft.appendChild(menu);
      sldMenuResponsive(true);
    }
    // if header main not contains menu
    else if (containerMain.querySelector('#amegamenu') === null && !activeMenuMobile) {
      containerMain.appendChild(menu);
      sldMenuResponsive(false);
    }
  }
}

function sldMenuResponsive(active) {
  if (active) {
    sldBackLink();
    sldSubMenuSlide();
  } else {
    sldDisableSubMenuSlide();
  }
}

function sldBackLink() {
  const menuContainer = document.querySelector('.st-menu .anav-top');
  const itemsContainer = document.querySelectorAll('.dropdown-content:first-child, .sld-menu-container-items, .sld-category-subs');
  const linksBack = document.querySelector('.sld-js-menu-back-button');
  if (itemsContainer.length > 0 && linksBack === null) {
    itemsContainer.forEach(function (container) {
      const item = sldCreateButtonBack();
      item.addEventListener('click', function (e) {
        e.preventDefault();
        const submenu = this.closest('ul');
        const submenuParent = submenu.parentElement.closest('ul');
        if (submenuParent.classList.contains('anav-top')) {
          menuContainer.style.height = '';
        } else {
          menuContainer.style.height = submenuParent.querySelector('.dropdown-content').offsetHeight + 'px';
        }
        submenu.classList.remove('active');
      });
      container.prepend(item);
    });
  }
}

function sldCreateButtonBack() {
  const icon = document.createElement('span');
  icon.classList.add('material-icons');
  icon.innerText = 'keyboard_arrow_left';
  const button = document.createElement('button');
  button.classList.add('sld-js-menu-back-button');
  button.append(icon);
  button.append(document.createTextNode('retour'));
  const item = document.createElement('li');
  item.classList.add('sld-menu-back-link-mobile');
  item.append(button);
  return item;
}

function sldSubMenuSlide() {
  const buttonSubmenu = document.querySelectorAll('.sld-js-menu-submenu-button');
  if (buttonSubmenu.length > 0) {
    buttonSubmenu.forEach(function (button, key) {
      button.addEventListener('click', sldActiveSubmenu, true);
    });
  }
}

function sldDisableSubMenuSlide() {
  const buttonSubmenu = document.querySelectorAll('.sld-js-menu-submenu-button');
  const dropdowns = document.querySelectorAll('.adropdown');
  if (buttonSubmenu.length > 0) {
    buttonSubmenu.forEach((button) => {
      button.removeEventListener('click', sldActiveSubmenu, true);
    });
  }
  if (dropdowns.length > 0) {
    dropdowns.forEach((dropdown) => {
      dropdown.style.height = null;
    })
  }
}

function sldActiveSubmenu(event) {
  event.preventDefault();
  const menuContainer = document.querySelector('.st-menu .anav-top');
  const menuDropdown = document.querySelector('.st-menu .adropdown');
  const submenu = this.nextElementSibling;
  const submenuContent = submenu.querySelector('.dropdown-content');
  if (submenu) {
    submenu.classList.add('active');
    if (submenuContent) {
      menuContainer.style.height = submenuContent.offsetHeight + 'px';
      menuDropdown.style.height = submenuContent.offsetHeight + 'px';
    }
    else {
      menuContainer.style.height = submenu.offsetHeight + 'px';
      menuDropdown.style.height = submenu.offsetHeight + 'px';
    }
  }
}

function sldDisableSubmenu(button, menuContainer) {
  const submenu = button.closest('ul');
  if (submenu !== null) {
    submenu.classList.remove('active');
    if (submenu.parentElement.closest('ul').classList.contains('anav-top')) {
      menuContainer.style.height = 'auto';
    } else {
      menuContainer.style.height = submenu.parentElement.closest('ul').offsetHeight.concat('px');
    }
  }
}

window.addEventListener('load', () => {
  setTimeout(() => {
    ajaxLoadDrodownContent();
    updateDropdownPosition();
  }, 600);
  // enableHoverMenuOnTablet();
});

// event resize
window.addEventListener('resize', () => {
  sldBreakWidth();
});
