import $ from 'jquery';
import prestashop from 'prestashop';

$(document).ready(function () {
  createProductSpin();
  createInputFile();
  zoomImage();
  lightboxImage();
  coverImage();
  imageScrollBox();
  mobileImageScrollBox();
  moveProductAttributes();
  addAccordionActiveClass();
  sldCheckWishlist();

  $('body').on('change', '.product-variants *[name]', function () {
    togglePendingRefreshIcon('in');
  });

  prestashop.on('updatedProduct', function (event) {
    createInputFile();
    zoomImage();
    coverImage();
    imageScrollBox();
    mobileImageScrollBox();
    moveProductAttributes();

    if (event && event.product_minimal_quantity) {
      const minimalProductQuantity = parseInt(event.product_minimal_quantity, 10);
      const quantityInputSelector = '#quantity_wanted';
      let quantityInput = $(quantityInputSelector);

      quantityInput.trigger('touchspin.updatesettings', {min: minimalProductQuantity});
    }
    
    $('#js_mfp_gallery').replaceWith(event.product_images_modal);
    lightboxImage();
    togglePendingRefreshIcon('out');

    if ($('[data-button-action="add-to-cart"]').is(':disabled')) {
      $('.product-add-to-cart').addClass('add-to-cart-disabled');
    } else {
      $('.product-add-to-cart').removeClass('add-to-cart-disabled');
    }
  });

  // varCustomActionAddToCart
  handleAddToCart();  
  
});

var productResizeTimer;
$(window).on('resize', function() {
  clearTimeout(productResizeTimer);
  productResizeTimer = setTimeout(function() {
    zoomImage();
  }, 250);
});

function sldCheckWishlist(){
  if($('#add_to_wishlist_button').length > 0){
    var srcButton = $('#add_to_wishlist_button');
    var wishlistButton = '<a id="sld_add_to_wishlist_button" href="javascript:;">'+srcButton.html()+'</a>';
    $(wishlistButton).prependTo('.main-product-details .product-cover .layer');

    $('#sld_add_to_wishlist_button').on('click', function(e){
      e.preventDefault();
      srcButton.click();
    });
  }
}

// overwrite 'add-to-cart'
function handleAddToCart() {
  $('body').off('click', '[data-button-action="add-to-cart"]');
  $('body').on('click', '[data-button-action="add-to-cart"]', function (event) {
    event.preventDefault();
    let $btn = $(this);

    if ($('#quantity_wanted').val() > $('[data-stock]').data('stock') && $('[data-allow-oosp]').data('allow-oosp').length === 0) {
      $btn.attr('disabled', 'disabled');
    } else {
      let $form = $(event.target).closest('form');
      let query = $form.serialize() + '&add=1&action=update';
      let actionURL = $form.attr('action');

      let isQuantityInputValid = function($input) {
        var validInput = true;

        $input.each(function (index, input) {
          let $input = $(input);
          let minimalValue = parseInt($input.attr('min'), 10);
          if (minimalValue && $input.val() < minimalValue) {
            onInvalidQuantity($input);
            validInput = false;
          }
        });

        return validInput;
      };

      let onInvalidQuantity = function($input) {
        $input.parents('.product-add-to-cart').first().find('.product-minimal-quantity').addClass('error');
        $input.parent().find('label').addClass('error');
      };

      let $quantityInput = $form.find('input[min]');
      if (!isQuantityInputValid($quantityInput)) {
        onInvalidQuantity($quantityInput);

        return;
      }

      $btn.removeClass('added').addClass('disabled');

      $.post(actionURL, query, null, 'json').then(function(resp) {
        prestashop.emit('updateCart', {
          reason: {
            idProduct: resp.id_product,
            idProductAttribute: resp.id_product_attribute,
            idCustomization: resp.id_customization,
            linkAction: 'add-to-cart',
            cart: resp.cart
          },
          resp
        });

        if (resp.success) {
          if (typeof(varPSAjaxCart) !== 'undefined' && varPSAjaxCart) {
            $btn.addClass('added');
          } else {
            window.location.href = prestashop.urls.pages.cart + '?action=show';
          }
        }
        //if (resp.hasError) {
          //$('.js-modal-message-text').text(resp.errors[0]);
          //$('.js-modal-message').modal('show');
        //}
      }).fail(function (resp) {
        prestashop.emit('handleError', { eventType: 'addProductToCart', resp });
      });
    }
  });
}

function togglePendingRefreshIcon(fade_status) {
  // varProductPendingRefreshIcon
  if (fade_status == 'in') {
    $('.js-product-refresh-pending-query').fadeIn();
  } else {
    $('.js-product-refresh-pending-query').fadeOut();
  }
}

function zoomImage() {
  $('#product .zoomWrapper .js-main-zoom').css('position','static').unwrap();
  $('#product .zoomContainer').remove();

  $('#product .js-enable-zoom-image .js-main-zoom').elevateZoom({
    zoomType: 'inner',
    gallery: 'js-zoom-gallery',
    galleryActiveClass: 'selected',
    imageCrossfade: true,
    cursor: 'crosshair',
    easing: true,
    easingDuration: 300,
    zoomWindowFadeIn: 500,
    zoomWindowFadeOut: 300,
  });
}

function lightboxImage() {
  const $gallery = $('#js_mfp_gallery');
  if ($gallery.length) {
    let zClose = $gallery.data('text-close'),
        zPrev = $gallery.data('text-prev'),
        zNext = $gallery.data('text-next');

    $('.js_mfp_gallery_item').magnificPopup({
      type: 'image',
      tClose: zClose,
      tLoading: '<div class="uil-spin-css"><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></div>',
      removalDelay: 500,
      mainClass: 'mfp-fade',
      closeOnBgClick: true,
      gallery: {
        enabled: true,
        tPrev: zPrev,
        tNext: zNext,
        tCounter: '%curr% / %total%',
      },
      image: {
        verticalFit: false,
        titleSrc: function(item) {
          if (item.el.attr('data-title')) {
            return '<span>' + item.el.attr('data-title') + '</span>';
          } else {
            return '';
          }
        }
      }
    });

    $('.js-mfp-button').on('click', function() {
      //let imageId = $('#js-zoom-gallery .js-thumb.selected').data('id-image');
      $('.js_mfp_gallery_item').filter('[data-id-image="' + $('#js-zoom-gallery .js-thumb.selected').data('id-image') + '"]').trigger('click');
    });
  }
}

function coverImage() {
  $('#product .js-cover-image .js-thumb').on(
    'click',
    function (event) {
      $('.js-thumb.selected').removeClass('selected');
      $(event.currentTarget).addClass('selected');
      $('.js-qv-product-cover').prop('src', $(event.currentTarget).data('image'));
    }
  );
}

function imageScrollBox() {
  $('.js-product-thumbs-scrollbox').makeFlexScrollBox();
}
function mobileImageScrollBox() {
  $('.js-mobile-images-scrollbox').makeFlexScrollBox();
}


/*function showHideScrollBoxArrows() {
  let thumbsWidth = 0;
  $('.js-qv-product-images .js-thumb-container').each(function() {
    thumbsWidth = thumbsWidth + $(this).outerWidth();
  });

  if (($('.js-qv-product-images').width() + 5) < thumbsWidth) {
    $('.scroll-box-arrows').addClass('scroll');
  } else {
    $('.scroll-box-arrows').removeClass('scroll');
  }
}*/

function createInputFile() {
  $('.js-file-input').on('change', function(event) {
    let target, file;

    if ((target = $(event.currentTarget)[0]) && (file = target.files[0])) {
      $(target).prev().text(file.name);
    }
  });
}

function createProductSpin() {
  const $quantityInput = $('#quantity_wanted');
  $quantityInput.makeTouchSpin();

  $('body').on('change keyup', '#quantity_wanted', function (e) {
    $(e.currentTarget).trigger('touchspin.stopspin');
    prestashop.emit('updateProduct', {
      eventType: 'updatedProductQuantity',
      event: e
    });
  });
}

function moveProductAttributes() {
  let $src = $('.js-product-attributes-source'),
      $dest = $('.js-product-attributes-destination'),
      $src2 = $('.js-product-availability-source'),
      $dest2 = $('.js-product-availability-destination');
  $dest.empty();
  if ($src.length) {
    $dest.html($src.html()); //$src.remove();
  }
  $dest2.empty();
  if ($src2.length) {
    $dest2.html($src2.html()); //$src2.remove();
  }
}

function addAccordionActiveClass() {
  $('.js-product-accordions [data-toggle="collapse"]').on('click', function() {
    $(this).closest('.panel').toggleClass('active');
  });
}
